import React from "react";
import { NavLink } from "react-router-dom";
import 'animate.css';
import { BsCashCoin } from "react-icons/bs";
import { MdSupportAgent, MdVerified } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import TrackVisibility from 'react-on-screen';
import { motion } from 'framer-motion';
// import Intro from '../../assets/video/intro.mp4';
// import TwentyFT from '../../assets/img/20ft.png';
// import TwentyTwoFT from '../../assets/img/22ft.png';
// import ThirtyTwoFTSXL from '../../assets/img/sxl.png';
// import ThirtyTwoFTMXL from '../../assets/img/mxl.png';
// import Loc from '../../assets/img/route.png';
// import Load from '../../assets/img/load.png';
// import Driver from '../../assets/img/driver.png';
// import Person from '../../assets/img/person.png';

function Home() {
  return (
    <motion.div className="container-fluid" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
      {/* Video background */}
      <div className="relative w-full h-screen overflow-hidden">
        <video className="absolute top-0 left-0 p-2 w-full h-full object-cover opacity-90" autoPlay loop muted playsInline>
          <source src={"https://res.cloudinary.com/di6sgq793/video/upload/v1729523846/intro_as9acb.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Centered Heading */}
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold animate__animated animate__fadeInUp text-center">
            A WAY TO NEW DIGITAL FLEET BROKERAGE
          </h1>
        </div>

        {/* Centered Buttons */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-20 flex gap-4 flex-wrap justify-center">
          <NavLink to={"/livetrucks"}>
            <button className="relative bg-black text-white border-2 border-yellow-500 px-4 py-2 md:px-6 md:py-4 text-md md:text-lg font-semibold overflow-hidden transition-all ease-in-out group hover:bg-white hover:text-black hover:border-black">
              <span className="relative z-10">Live Trucks</span>
              <div className="absolute inset-0 bg-white w-0 h-full transition-all duration-300 ease-in-out group-hover:w-full"></div>
            </button>
          </NavLink>
          <NavLink to={"/liveloads"}>
            <button className="bg-transparent text-white border-2 border-white px-4 py-2 md:px-6 md:py-4 text-md md:text-lg font-semibold">
              Live Loads
            </button>
          </NavLink>
        </div>
      </div>


      {/* Freight Network and Scrolling Container */}
      <div className="flex flex-col lg:flex-row items-start justify-between mx-4 md:mx-10 py-10 gap-10">
        {/* Freight Network Content */}
        <TrackVisibility>
          {({ isVisible }) => (
            <div className={`${isVisible ? 'animate__animated animate__fadeInLeft' : ''} bg-[#FFED00] p-6 shadow-lg rounded-lg text-black w-full lg:w-2/2`}>
              <h2 className="text-2xl font-semibold mb-4">India's only growing fastest Digital Freight Network</h2>
              <ul className="list-disc pl-6">
                <li className="mb-2">Budget friendly freight among the market</li>
                <li className="mb-2">Capitalized by technology</li>
                <li className="mb-2">Easy Booking by just one click or one call</li>
                <li className="mb-2">24/7 customer support</li>
                <li className="mb-2">Safe Delivery all across India</li>
              </ul>
            </div>
          )}
        </TrackVisibility>

        {/* Auto-scrolling images */}
        <div className="relative flex flex-col items-center justify-center w-full lg:w-1/2 animate__animated animate__fadeInRight">
          <h2 className="text-2xl font-semibold mb-4">Emerging Leaders in 20 Ft | 22 Ft | 32 Ft SXL | 32 Ft MXL Containers.</h2>
          <div className="overflow-hidden w-full">
            <div className="scroll-container flex gap-8 w-max animate-scroll">
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/20ft_hmrs2m.webp"} alt="20ft container" />
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/22ft_dbpyst.webp"} alt="22ft container" />
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523714/sxl_pwzw6g.webp"} alt="32ft sxl container" />
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523715/mxl_dmd21j.webp"} alt="32ft mxl container" />
              {/* Repeat images for continuous scroll */}
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/20ft_hmrs2m.webp"} alt="20ft container" />
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/22ft_dbpyst.webp"} alt="22ft container" />
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523714/sxl_pwzw6g.webp"} alt="32ft sxl container" />
              <img className="w-48 mx-5 aspect-[3/2] object-contain h-auto" src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523715/mxl_dmd21j.webp"} alt="32ft mxl container" />
            </div>
          </div>
        </div>
      </div>

      {/* Centered "Verified Trucks" Section */}
      <div className="relative text-left py-20 px-4 md:px-10 flex justify-center">
        <div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Verified Trucks,</h2>
          <h2 className="text-3xl md:text-4xl font-bold">Reduced Shipping Cost & More Visibility</h2>
        </div>
      </div>

      {/* Route Optimization and Load Consolidation */}
      <div className="flex flex-col gap-20 py-20 px-4 md:px-10">
        {/* Route Optimization (Image on the right) */}
        <div className="flex flex-col-reverse lg:flex-row-reverse items-center gap-20 lg:gap-80">
          <div className="bg-[#FFED00] p-6 shadow-lg rounded-lg max-w-lg animate__animated animate__fadeInRight">
            <h2 className="text-2xl font-semibold mb-4">Route Optimization:</h2>
            <p className="text-lg">Implementing advanced route optimization algorithms can help fleet owners reduce fuel consumption, lower emissions, and decrease labor costs. By analyzing traffic patterns, road conditions, and delivery schedules, fleet owners can optimize routes to minimize unnecessary detours and reduce overall shipping costs.</p>
          </div>
          <TrackVisibility>
            {({ isVisible }) => (
              <img className={`${isVisible ? "animate__animated animate__fadeInLeft drop-shadow-custom" : ""} w-96 h-auto`} src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523713/route_s9cfne.webp"} alt="Route" />
            )}
          </TrackVisibility>
        </div>

        {/* Load Consolidation (Image on the left) */}
        <div className="flex flex-col-reverse lg:flex-row items-center gap-20 lg:gap-80">
          <div className="bg-[#FFED00] p-6 shadow-lg rounded-lg max-w-lg animate__animated animate__fadeInLeft">
            <h2 className="text-2xl font-semibold mb-4">Load Consolidation:</h2>
            <p className="text-lg">Consolidating shipments can help fleet owners reduce the number of vehicles on the road, resulting in lower fuel costs, reduced labor expenses, and decreased wear and tear on vehicles.</p>
          </div>
          <TrackVisibility>
            {({ isVisible }) => (
              <img
                className={`${isVisible ? "animate__animated animate__zoomInDown drop-shadow-custom" : ""} w-96 h-auto`}
                src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/load_hh6dgg.webp"}
                alt="Load"
              />
            )}
          </TrackVisibility>
        </div>
      </div>

      {/* Driver Section (Image on the right) */}
      <div className="flex flex-col lg:flex-row items-center justify-between py-20 px-4 md:px-10 gap-20 lg:gap-40">
        <TrackVisibility>
          {({ isVisible }) => (
            <img
              className={`${isVisible ? "animate__animated animate__fadeInLeft drop-shadow-custom" : ""} w-full lg:w-3/4 max-w-3xl`} 
              src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/driver_gz3nw7.webp"} alt="Driver"
            />
          )}
        </TrackVisibility>
        <div className="bg-white shadow-lg rounded-lg p-6 w-full lg:w-1/2 animate__animated animate__fadeInRight">
          <ul className="list-disc pl-6">
            <li className="mb-2">We provide Consistent Loads,</li>
            <li className="mb-2">Lesser Halting to Maximize Earnings</li>
            <li className="mb-2">Go trans enables you to earn more regularly with minimized halting</li>
            <li className="mb-2">Zero percent commission from truck owner.</li>
            <li className="mb-2">On-time payment settlement.</li>
            <li className="mb-2">Enabling Truckers & Transporters to transact Transparently, Efficiently and Profitably.</li>
          </ul>
        </div>
      </div>

      {/* Map Section */}
      <div className="flex flex-col lg:flex-row-reverse items-center justify-between py-20 px-4 md:px-10 gap-20 lg:gap-40">
        <TrackVisibility>
          {({ isVisible }) => (
            <img
              className={`${isVisible ? "animate__animated animate__zoomInDown drop-shadow-custom" : ""} w-full lg:w-3/4 max-w-3xl`}  // Increased width and max width
              src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523713/person_ljir3r.webp"} alt="Truck Driver"
            />
          )}
        </TrackVisibility>
        <div className="relative bg-white shadow-lg rounded-lg p-6 w-full lg:w-1/2 text-center">
          <img
            src="https://www.garykirstencricketindia.in/wp-content/uploads/2020/07/GKCIPresence-Map-1.png"
            alt="Map"
            className="w-full max-w-md mx-auto mb-6"
          />
          <div className="absolute top-4 right-4 text-left max-w-md mx-auto text-[0.6rem] lg:text-lg">
            <h2 className="font-bold mb-4">Transporters: 700+ transporters</h2>
            <h2 className="font-bold mb-4">Truckers: 6000+ truckers</h2>
          </div>
        </div>
      </div>


      {/* Info Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 py-10 px-10 text-center animate__animated animate__fadeInUp">
        <div className="bg-gray-200 p-6 shadow-lg rounded-lg">
          <h4 className="text-xl font-bold mb-4">Competitive Pricing</h4>
          <p>Get the best rate from market place you bid and get the load ready.</p>
          <BsCashCoin size={80} className="mx-auto mt-4" />
        </div>

        <div className="bg-gray-200 p-6 shadow-lg rounded-lg">
          <h4 className="text-xl font-bold mb-4">24/7 Support</h4>
          <p>Get 24/7 customer support for any on route discrepancy.</p>
          <MdSupportAgent size={80} className="mx-auto mt-4" />
        </div>

        <div className="bg-gray-200 p-6 shadow-lg rounded-lg">
          <h4 className="text-xl font-bold mb-4">Verified Truck Discovery</h4>
          <p>KYC verified truckers available PAN India to connect with transporters</p>
          <MdVerified size={80} className="mx-auto mt-4" />
        </div>

        <div className="bg-gray-200 p-6 shadow-lg rounded-lg">
          <h4 className="text-xl font-bold mb-4">Real Time Tracking</h4>
          <p>GPS enabled vehicle support to justify real time tracking and safe delivery of shipment.</p>
          <FaMapLocationDot size={80} className="mx-auto mt-4" />
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
