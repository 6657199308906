import React, { useEffect, useState, useRef } from 'react';
import { Filter, X, ChevronDown } from 'lucide-react';
import axios from 'axios';
import { Bars } from 'react-loading-icons';
import NotFound from './NotFound';
import { motion } from 'framer-motion';


const vehicleTypes = [
    "22 Ft Container", "24 Ft Container", "32 Ft SXL Container", "32 Ft MXL Container", "34 Ft Container"
];

const cityOptions = [
    "Ahmedabad", "Aizawl", "Akola", "Amalner", "Amravati", "Amritsar", "Anand", "Anantapur",
    "Anakapalle", "Aurangabad", "Azamgarh", "Baharampur", "Bagaha", "Balaghat", "Balurghat",
    "Baleshwar Town", "Barnala", "Baramula", "Baripada Town", "Barmer", "Bharatpur", "Bharuch",
    "Bhilai Nagar", "Bhilai", "Bhilwara", "Bhopal", "Bhuj", "Bhagalpur", "Bhadrak", "Bilaspur",
    "Bina", "Biraul", "Bokaro Steel City", "Bombay", "Brahmapur", "Chandausi", "Chandigarh",
    "Chilakaluripet", "Chittoor", "Coimbatore", "Cuttack", "Deesa", "Deoghar", "Dhanbad",
    "Dantewada", "Darbhanga", "Dimapur", "Dibrugarh", "Durg", "Dhule", "Dharmavaram", "Etawah",
    "Firozabad", "Firozpur", "Faridabad", "Faridkot", "Ganjbasoda", "Gandhinagar", "Guntur",
    "Gondia", "Gulbarga", "Gurgaon", "Guwahati", "Habra", "Hajipur", "Haldwani-cum-Kathgodam",
    "Hansi", "Hisar", "Hazaribag", "Hugli-Chinsurah", "Ichalkaranji", "Imphal", "Indore",
    "Jabalpur", "Jaisalmer", "Jammu", "Jamshedpur", "Jalgaon", "Jalpaiguri", "Jalandhar",
    "Jhansi", "Jhumri Tilaiya", "Jind", "Jorhat", "Jodhpur", "Kadapa", "Kakinada", "Kalyan-Dombivali",
    "Kamptee", "Kancheepuram", "Karimnagar", "Kashipur", "Kolkata", "Kolar", "Korba", "Kothagudem",
    "Kurnool", "Kozhikode", "Latur", "Lakhisarai", "Lakhimpur", "Ludhiana", "Loni", "Lucknow",
    "Madhubani", "Madurai", "Mahabubnagar", "Maharajganj", "Mangalore", "Mysore", "Madanapalle",
    "Malerkotla", "Mandya", "Mangaluru", "Mandsaur", "Marmagao", "Medinipur", "Muzaffarpur",
    "Moradabad", "Morvi", "Nagapattinam", "Nagda", "Nagpur", "Nanded", "Nanded-Waghala", "Nashik",
    "Nellore", "Neyveli (TS)", "New Delhi", "Noida", "Nagarcoil", "Navi Mumbai", "Ongole", "Orai",
    "Palakkad", "Pali", "Panchkula", "Panaji", "Panipat", "Parbhani", "Patan", "Pathankot",
    "Pudukkottai", "Purnia", "Purulia", "Raayachuru", "Rae Bareli", "Rajkot", "Rajampet",
    "Rajasthan", "Rajnandgaon", "Ramgarh", "Rampur", "Ranchi", "Ratlam", "Rewari", "Rudrapur",
    "Salem", "Sambalpur", "Sambhal", "Sangli", "Satna", "Saurashtra", "Siliguri", "Srinagar",
    "Shillong", "Shimla", "Shivamogga", "Shivpuri", "Sitapur", "Sitamarhi", "Suryapet",
    "Tadepalligudem", "Tadpatri", "Theni Allinagaram", "Thiruvananthapuram", "Tiruchirappalli",
    "Tirupati", "Tumkur", "Udhagamandalam", "Udaipur", "Udupi", "Ujjain", "Vadodara", "Valsad",
    "Varanasi", "Vapi", "Vellore", "Viluppuram", "Visakhapatnam", "Warangal", "Washim",
    "Wardha", "Yavatmal", "Yemmiganur", "Yamunanagar", "Zirakpur"
];


const filterFields = [
    { value: "vehicleType", label: "Vehicle Type" },
    { value: "vehicleAvailableAt", label: "Vehicle Available At" },
    { value: "preferredRoute", label: "Preferred Route" },
    { value: "status", label: "Status" }
];

const statusOptions = ["Available", "Booked"];

const LiveTrucks = () => {
    const [liveTrucks, setLiveTrucks] = useState([]); 
    const [filteredTrucks, setFilteredTrucks] = useState([]);
    const [filters, setFilters] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownRefs = useRef({});
    const [loading, setLoading] = useState(true);


    // Fetch data from backend
    useEffect(() => {
        const fetchLiveTrucks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_POST_URL}/api/livetrucks`);
                setLiveTrucks(response.data);
                setFilteredTrucks(response.data);
            } catch (error) {
                console.error('Error fetching live trucks:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLiveTrucks();
    }, []);

    // Handle outside clicks for dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (activeDropdown !== null) {
                const activeRef = dropdownRefs.current[activeDropdown];
                if (activeRef && !activeRef.contains(event.target)) {
                    setActiveDropdown(null);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activeDropdown]);

    const formatDate = (date) => {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const addFilter = () => {
        setFilters([...filters, {
            field: filterFields[0].value,
            value: ''
        }]);
    };

    const removeFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
        applyFilters(newFilters);
    };

    const updateFilter = (index, key, value) => {
        const newFilters = [...filters];
        newFilters[index] = { ...newFilters[index], [key]: value };
        setFilters(newFilters);
        applyFilters(newFilters);
    };

    const toggleDropdown = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    const handleOptionSelect = (index, value) => {
        updateFilter(index, 'value', value);
        setActiveDropdown(null);
    };

    const getOptionsForField = (field) => {
        switch (field) {
            case 'vehicleType':
                return vehicleTypes;
            case 'status':
                return statusOptions;
            case 'vehicleAvailableAt':
            case 'preferredRoute':
                return cityOptions;
            default:
                return [];
        }
    };

    const renderFilterInput = (filter, index) => {
        const options = getOptionsForField(filter.field);
        const isDropdownField = ['vehicleType', 'status', 'vehicleAvailableAt', 'preferredRoute'].includes(filter.field);

        return (
            <div className="relative flex-1" ref={el => dropdownRefs.current[index] = el}>
                <div className="relative">
                    <input
                        type="text"
                        className="border rounded-md px-3 py-2 text-sm w-full pr-8 focus:outline-none border-2 border-black placeholder-black"
                        placeholder="Enter value..."
                        value={filter.value}
                        onChange={(e) => updateFilter(index, 'value', e.target.value)}
                        onClick={() => isDropdownField && toggleDropdown(index)}
                    />
                    {isDropdownField && (
                        <ChevronDown
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-500"
                            onClick={() => toggleDropdown(index)}
                        />
                    )}
                </div>

                {activeDropdown === index && isDropdownField && (
                    <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                        {options
                            .filter(option =>
                                option.toLowerCase().includes(filter.value.toLowerCase())
                            )
                            .map((option, i) => (
                                <div
                                    key={i}
                                    className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                                    onClick={() => handleOptionSelect(index, option)}
                                >
                                    {option}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        );
    };


    const applyFilters = (currentFilters) => {
        let filtered = [...liveTrucks];

        currentFilters.forEach(filter => {
            if (filter.value) {
                filtered = filtered.filter(truck => {
                    const fieldValue = truck[filter.field]?.toLowerCase() || '';
                    const filterValue = filter.value.toLowerCase();
                    return fieldValue.includes(filterValue);
                });
            }
        });

        setFilteredTrucks(filtered);
    };

    const clearAllFilters = () => {
        setFilters([]);
        setFilteredTrucks(liveTrucks);
    };

    const FilterByCity = ({ city }) => {
        const handleFilter = () => {
            const newFilter = { field: 'vehicleAvailableAt', value: city };
            setFilters([newFilter]);
            applyFilters([newFilter]);
        };

        return (
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
                onClick={handleFilter}
            >
                {city} Trucks
            </button>
        );
    };

  // WhatsApp click handler
  const handleWhatsAppClick = (truck) => {
    const message = `Hi, ${truck.partnerName} I'm interested in to book the truck with the following details:\n\nVehicle Type: ${truck.vehicleType}\nTonnage: ${truck.tonnage}\nVehicle Available At: ${truck.vehicleAvailableAt}\nPreferred Route: ${truck.preferredRoute}\n\n Thanks & Regards.`;
    const whatsappURL = `https://wa.me/+91${truck.phoneNumber}?text=${encodeURIComponent(message)}`;

    window.location.href = whatsappURL;
  };

  if (loading) return <div className='flex items-center justify-center min-h-screen'><Bars className='loading-bar' stroke="black" /> </div>;
  return (
    <motion.div className="p-4 max-w-full lg:h-auto bg-gray-100" initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}>
            
            {liveTrucks.length === 0 ? (
                <NotFound title={"Trucks available from morning 8:30 AM to 11:30 PM(IST)"} />
            ) : (
                <>
                    <h2 className="text-5xl lg:text-6xl text-center font-bold mb-10 mt-40">Live Trucks</h2>

                    {/* Filter Section */}
                    <div className="mb-6 bg-white rounded-lg shadow p-4 lg:mx-10">
                        <p className='text-sm text-red-600 mb-4'>Note: - Use filter option to search your suitable trucks</p>
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center space-x-2">
                                <Filter className="w-5 h-5" />
                                <span className="text-sm font-medium">Filter</span>
                            </div>
                            <button
                                className="text-sm text-gray-600 hover:text-gray-900"
                                onClick={clearAllFilters}
                            >
                                Clear all filters
                            </button>
                        </div>

                        {filters.map((filter, index) => (
                            <div key={index} className="flex items-center space-x-2 mb-2">
                                <select
                                    className="border rounded-md px-3 py-2 text-sm focus:outline-none border-2 border-black"
                                    value={filter.field}
                                    onChange={(e) => updateFilter(index, 'field', e.target.value)}
                                >
                                    {filterFields.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>

                                {renderFilterInput(filter, index)}

                                <button
                                    className="p-2 hover:bg-gray-100 rounded-md"
                                    onClick={() => removeFilter(index)}
                                >
                                    <X className="w-4 h-4" />
                                </button>
                            </div>
                        ))}

                        <button
                            className="mt-2 text-sm text-gray-600 hover:text-gray-900 flex items-center space-x-1"
                            onClick={addFilter}
                        >
                            <span>Add filter</span>
                            <ChevronDown className="w-4 h-4" />
                        </button>
                    </div>

                    <div className="lg:mx-10 mx-0 flex flex-wrap gap-2 justify-center lg:justify-start">
                        <FilterByCity city="Kolkata" />
                        <FilterByCity city="New Delhi" />
                        <FilterByCity city="Mumbai" />
                        <FilterByCity city="Chennai" />
                        <FilterByCity city="Bangalore" />
                        <FilterByCity city="Guwahati" />
                        <FilterByCity city="Nagpur" />
                    </div>

                     {/* Live Loads Table (for larger screens) */}
                     <div className="hidden md:block lg:mx-10 overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md overflow-hidden">
                            <thead className="bg-black text-white">
                                <tr>
                                    <th className="py-2 px-4 text-left">Posted At</th>
                                    <th className="py-2 px-4 text-left">Partner's Name</th>
                                    <th className="py-2 px-4 text-left">Partner's Contact</th>
                                    <th className="py-2 px-4 text-left">Vehicle Type</th>
                                    <th className="py-2 px-4 text-left">Tonnage</th>
                                    <th className="py-2 px-4 text-left">Vehicle Available At</th>
                                    <th className="py-2 px-4 text-left">Preferred Route</th>
                                    <th className="py-2 px-4 text-left">Status</th>
                                    <th className="py-2 px-4 text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTrucks.length > 0 ? (
                                    filteredTrucks.map((truck) => (
                                        <tr key={truck._id} className="border-t">
                                            <td className="py-2 px-4">{formatDate(truck.createdAt)}</td>
                                            <td className="py-2 px-4">{truck.partnerName}</td>
                                            <td className="py-2 px-4">{truck.phoneNumber}</td>
                                            <td className="py-2 px-4">{truck.vehicleType}</td>
                                            <td className="py-2 px-4">{truck.tonnage}</td>
                                            <td className="py-2 font-bold px-4">{truck.vehicleAvailableAt}</td>
                                            <td className="py-2 font-bold px-4">{truck.preferredRoute}</td>
                                            <td className="py-2 px-4">
                                                <span className={truck.status === 'Available' ? 'text-green-500' : 'text-red-500'}>
                                                    {truck.status}
                                                </span>
                                            </td>
                                            <td className="py-2 px-4">
                                                {truck.status === 'Available' && (
                                                    <button
                                                        className="bg-green-500 text-white px-4 py-2 rounded-lg"
                                                        onClick={() => handleWhatsAppClick(truck)}
                                                    >
                                                        Book Truck
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="12" className="py-4 text-center text-black">
                                            No Trucks Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>


                     {/* Live Loads Cards (for smaller screens) */}
                     <div className="block md:hidden">
                        {filteredTrucks.length > 0 ? (
                            filteredTrucks.map((truck) => (
                                <div key={truck._id} className="bg-white shadow-md rounded-lg p-4 mb-4">
                                    <h3 className="font-bold text-lg mb-0">Partner's Name: {truck.partnerName}</h3>
                                    <p className="font-semibold text-sm flex justify-left mb-2">
                                        <span>Partner's Contact:</span>
                                        <span> {truck.phoneNumber}</span>
                                    </p>
                                    <p className="text-sm flex justify-between">
                                        <span>Posted At:</span>
                                        <span>{formatDate(truck.createdAt)}</span>
                                    </p>
                                    <p className="text-sm flex justify-between">
                                        <span>Vehicle Type:</span>
                                        <span>{truck.vehicleType}</span>
                                    </p>
                                    <p className="text-sm flex justify-between">
                                        <span>Tonnage:</span>
                                        <span>{truck.tonnage}</span>
                                    </p>
                                    <p className="text-sm font-bold flex justify-between">
                                        <span>Vehicle Available At:</span>
                                        <span>{truck.vehicleAvailableAt}</span>
                                    </p>
                                    <p className="text-sm font-bold flex justify-between">
                                        <span>Preferred Route:</span>
                                        <span>{truck.preferredRoute}</span>
                                    </p>
                                    <p className="text-sm flex justify-between">
                                        Status:
                                        <span className={truck.status === 'Available' ? 'text-green-500' : 'text-red-500'}>
                                            {truck.status}
                                        </span>
                                    </p>
                                    {truck.status === 'Available' && (
                                        <button
                                            className="bg-green-500 text-white w-full mt-4 py-2 rounded-lg"
                                            onClick={() => handleWhatsAppClick(truck)}
                                        >
                                            Book Truck
                                        </button>
                                    )}
                                </div>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="12" className="py-4 text-center text-black">
                                    No Trucks Found
                                </td>
                            </tr>
                        )}
                    </div>
                </>
            )}
        </motion.div>
    );
}

export default LiveTrucks;