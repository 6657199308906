import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
//import logo from "../assets/img/logo-black.png";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Lock scroll when the menu is open
  //  useEffect(() => {
  //   if (showMenu) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }

  //   // Cleanup on component unmount
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [showMenu]);

  return (
    <div className="flex justify-center overflow-hidden">
      <nav className="fixed flex justify-between items-center px-6 py-4 bg-[#FFED00] w-[90%] mx-auto mt-2.5 z-50">
        {/* Logo */}
        <NavLink to="/" className="flex-shrink-0">
          <img src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523712/logo-black_cdds6j.webp"} alt="logo" className="h-16" />
        </NavLink>

        {/* Mobile Menu */}
        <div className={`fixed lg:hidden top-0 right-0 bg-black bg-opacity-30 backdrop-blur-md w-[80%] h-full p-6 z-50 transition-transform transform ${showMenu ? 'translate-x-0' : 'translate-x-full'}`}>
          <ul className="flex flex-col space-y-10">
            <li>
              <NavLink to="/" className="text-black text-xl" onClick={toggleMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/liveloads" className="text-black text-xl" onClick={toggleMenu}>
                Live Loads
              </NavLink>
            </li>
            <li>
              <NavLink to="/livetrucks" className="text-black text-xl" onClick={toggleMenu}>
                Live Trucks
              </NavLink>
            </li>
            <li>
              <NavLink to="/transporters" className="text-black text-xl" onClick={toggleMenu}>
                Transporters
              </NavLink>
            </li>
            <li>
              <NavLink to="/truckers" className="text-black text-xl" onClick={toggleMenu}>
                Truckers
              </NavLink>
            </li>
            <li>
              <NavLink to="/aboutus" className="text-black text-xl" onClick={toggleMenu}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/news" className="text-black text-xl" onClick={toggleMenu}>
                News
              </NavLink>
            </li>
            <li>
              <NavLink to="/contactus" className="text-black text-xl" onClick={toggleMenu}>
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transporters"
                className="bg-black text-white py-2 px-6 border-2 border-yellow-400 text-xl font-semibold hover:bg-white hover:text-black"
                onClick={toggleMenu}
              >
                Book a Truck
              </NavLink>
            </li>
          </ul>
          <div className="absolute top-6 right-6 text-black text-3xl cursor-pointer" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>


        {/* Menu Toggle for Mobile */}
        <div className="text-3xl text-black cursor-pointer lg:hidden" onClick={toggleMenu}>
          <IoMenu />
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-8 items-center">
          <li>
            <NavLink to="/" className="text-black font-semibold transition hover:text-yellow-500">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/liveloads" className="text-black font-semibold transition hover:text-yellow-500">
              Live Loads
            </NavLink>
          </li>
          <li>
            <NavLink to="/livetrucks" className="text-black font-semibold transition hover:text-yellow-500">
              Live Trucks
            </NavLink>
          </li>
          <li>
            <NavLink to="/transporters" className="text-black font-semibold transition hover:text-yellow-500">
              Transporters
            </NavLink>
          </li>
          <li>
            <NavLink to="/truckers" className="text-black font-semibold transition hover:text-yellow-500">
              Truckers
            </NavLink>
          </li>
          <li>
            <NavLink to="/aboutus" className="text-black font-semibold transition hover:text-yellow-500">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/news" className="text-black font-semibold transition hover:text-yellow-500">
              News
            </NavLink>
          </li>
          <li>
            <NavLink to="/contactus" className="text-black font-semibold transition hover:text-yellow-500">
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/transporters"
              className="bg-black text-white py-2 px-6 border-2 border-yellow-400 font-semibold transition hover:bg-white hover:text-black hover:border-black"
            >
              Book a Truck
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
